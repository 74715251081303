import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header";

const SubNumbers = () => {
  const handleBack = () => {
    window.history.back();
  };
  return (
    <div className="landing-wrapper">
      <div className="landing-container">
        <Header />
        <div className="side-info">
          <button className="back-button" onClick={handleBack}>
            <img src="/Images/back-button.svg" alt="back" /> Back
          </button>
          <h2 className="head-name">
            <span>N</span>umbers
          </h2>
        </div>

        <div className="sub-cat-wrapper">
          <div className="card-cover-1">
            <Link to="/learn-number">
              <img src="/Images/learn-number.jpg" alt="" />
              <span>Enter</span>
            </Link>
          </div>

          {/* <div className="card-cover-2">
                <Link to="/learn-number-quiz">
                  <img src="/Images/learn-number-quiz.jpg" alt="" />
                  <span>Play</span>
                </Link>
              </div> */}
        </div>
      </div>
    </div>
  );
};

export default SubNumbers;
