import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import LearnAlphabet from "./pages/learnAlphabet";
import LearnNumber from "./pages/learnNumber";
import LearnColor from "./pages/learnColor";
import NotFound from "./pages/notFound";
import SubAlphabets from "./pages/subCateogry/subAlphabets";
import SubNumbers from "./pages/subCateogry/subNumbers";
import SubColors from "./pages/subCateogry/subColors";
import LearnAlphabetQuiz from "./pages/learnAlphabetQuiz";
import LearnNumberQuiz from "./pages/learnNumberQuiz";
import LearnColorQuiz from "./pages/learnColorQuiz";
import "./App.css";
import LeaderBoard from "./pages/leaderboard/leaderboard";
import CertificatePage from "./pages/certificates/certificatePage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/learn-alphabet" element={<LearnAlphabet />} />
          <Route path="/learn-alphabet-quiz" element={<LearnAlphabetQuiz />} />

          <Route path="/learn-number" element={<LearnNumber />} />
          <Route path="/learn-number-quiz" element={<LearnNumberQuiz />} />

          <Route path="/learn-color" element={<LearnColor />} />
          <Route path="/learn-color-quiz" element={<LearnColorQuiz />} />

          <Route path="/sub-alphabet" element={<SubAlphabets />} />
          <Route path="/sub-number" element={<SubNumbers />} />
          <Route path="/sub-color" element={<SubColors />} />
          <Route path="/leader-board" element={<LeaderBoard />} />
          <Route path="/certificate" element={<CertificatePage />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <div className="goverment-logo">
        <img src="/Images/goverment.png" alt="" />
      </div>
    </div>
  );
}

export default App;
