import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header";

const SubAlphabets = () => {
  const handleBack = () => {
    window.history.back();
  };
  return (
    <div className="landing-wrapper">
      <div className="landing-container">
        <Header />
        <div>
          <div className="side-info">
            <button className="back-button" onClick={handleBack}>
              <img src="/Images/back-button.svg" alt="back" /> Back
            </button>
            <h2 className="head-name">
              <span>A</span>lphabets
            </h2>
          </div>

          <div className="sub-cat-wrapper">
            <div className="card-cover-1">
              <Link to="/learn-alphabet">
                <img src="/Images/learn-alphabet.jpg" alt="" />
                <span>Enter</span>
              </Link>
            </div>
            {/* <div className="card-cover-2">
                <Link to="/learn-alphabet-quiz">
                  <img src="/Images/learn-alphabet-quiz.jpg" alt="" />
                  <span>Play</span>
                </Link>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAlphabets;
