/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import HomeSlider from "./homeSlider";
import Header from "../components/header";

function Home() {
  return (
    <div className="landing-wrapper">
      <div className="landing-container">
        <Header />
        <div className="side-info">
          <h2 className="head-name">
            Mandaean <span>Kids Zone</span>
          </h2>
        </div>
        <div className="content">
          <HomeSlider />
          <div className="info-cover">
            <div className="d-flex justify-content-center">
              <div className="right-assets">
                <img src="/Images/cloud-1.svg" className="cloud-right" alt="" />
                <img
                  src="/Images/orange-ball.svg"
                  className="ball-right"
                  alt=""
                />
              </div>
              <div className="main-asset">
                <img src="/Images/cat.png" className="cat-img" alt="" />
              </div>
              <div className="left-assets">
                <img
                  src="/Images/blue-ball.svg"
                  className="blue-ball-left"
                  alt=""
                />
                <img src="/Images/cloud-2.svg" className="cloud-left" alt="" />
                <img
                  src="/Images/red-ball.svg"
                  className="red-ball-left"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
