import React from "react";
import Header from "../components/header";

function LearnNumberQuiz() {
  return (
    <div className="landing-wrapper">
        <div className="landing-container">
        <Header />
        <div className="game-wrapper">
            <div className="iframe-container">
                <iframe title="Game" src="https://mandaean-number-quiz.netlify.app"></iframe>
            </div>
        </div>
        </div>
    </div>
  );
}

export default LearnNumberQuiz;
