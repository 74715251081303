import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import LogIn from "../Auth/login";

const HomeSlider = () => {
  const [showLogin, setShowLogin] = useState(false);

  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNavigate = (path) => {
    const isAuthenticate = localStorage.getItem("token") ? true : false;
    if (isAuthenticate) {
      navigate(path);
    } else {
      setShowLogin(true);
    }
  };

  return (
    <div className="slider-container col-6  home-slider">
      <Slider {...settings}>
        <div
          className="cat-card "
          onClick={() => handleNavigate("/sub-alphabet")}
        >
          <img src="/Images/Alphabet.png" alt="" />
          <p>Alphabets</p>
        </div>

        <div className="cat-card" onClick={() => handleNavigate("/sub-number")}>
          <img src="/Images/Numbers.png" alt="" />
          <p>Numbers</p>
        </div>

        <div className="cat-card" onClick={() => handleNavigate("/sub-color")}>
          <img src="/Images/Color.png" alt="" />
          <p>Colours</p>
        </div>
      </Slider>

      <Modal
        size="md"
        show={showLogin}
        onHide={() => setShowLogin(!showLogin)}
        centered
        className="auth-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <LogIn setShowLogin={setShowLogin} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomeSlider;
