import React from "react";

import Header from "../../components/header";
import Certificate from "../../components/certificate";
import "./certificate.css";

const CertificatePage = () => {
  const handleBack = () => {
    window.history.back();
  };
  return (
    <div className="landing-wrapper">
      <div className="landing-container">
        <Header />
        <div>
          <div className="side-info">
            <button className="back-button" onClick={handleBack}>
              <img src="/Images/back-button.svg" alt="back" /> Back
            </button>
            <h2 className="head-name">
              <span>C</span>ertificate
            </h2>
          </div>

          <div className="sub-cat-wrapper">
            <Certificate
              studentName="Harshit Dubey"
              hours="5"
              topicTitle="Learning Alphabets"
              completionDate="August 25, 2024"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificatePage;
