import React from "react";
import Header from "../components/header";
import { useNavigate } from "react-router-dom";

function LearnColor() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };
  return (
    <div className="landing-wrapper">
      <div className="landing-container">
        <Header />
        <div className="side-info">
          <button className="back-button" onClick={handleBack}>
            <img src="/Images/back-button.svg" alt="back" /> Back
          </button>
        </div>
      </div>
      <div className="game-wrapper">
        <div class="iframe-container">
          <iframe
            title="Game"
            src="https://mandaean-learn-color.netlify.app"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default LearnColor;
