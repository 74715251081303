import * as yup from 'yup'
// const today = new Date();
// const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
export const RegisterSchema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName:yup.string().required('Last name is required'),
    userName:yup.string().required('Username is required'),
    email: yup
      .string()
      .email('Please enter valid email')
      .required('Email is required'),
    country: yup
      .string()
      .required('Please select your country'),
    dateOfBirth: yup
      .date()
      .required('Date of birth is required'),
    password: yup.string().required('Password is required'),
  })
  export const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter valid email')
      .required('Email is required'),
    password: yup.string().required('Password is required'),
  })