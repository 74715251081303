import React from "react";

const Certificate = ({ studentName, hours, topicTitle, completionDate }) => {
  return (
    <div className="certificate-container">
      <div className="certificate">
        <div className="water-mark-overlay"></div>
        <div className="certificate-header">
          <img src="/Images/logo.png" className="logo" alt="Logo" />
        </div>
        <div className="certificate-body">
          <p className="certificate-title">
            <strong>The Sabian Mandean </strong>
          </p>
          <h1>Certificate of Completion</h1>
          <p className="student-name">{studentName}</p>
          <div className="certificate-content">
            <div className="about-certificate">
              <p>
                has completed {hours} hours on {topicTitle} online on Date{" "}
                {completionDate}
              </p>
            </div>
            <p className="topic-title">
              The Topic consists of {hours} Continuity hours and includes the
              following:
            </p>
            <div className="text-center">
              <p className="topic-description ">
                Contract administrator - Types of claim - Claim Strategy - Delay
                analysis - The preliminaries to a claim - The essential elements
                to a successful claim - Responses - Claim preparation and
                presentation
              </p>
            </div>
          </div>
          <div className="certificate-footer ">
            <div className="row">
              <div className="col-md-6">
                <p>Principal: ______________________</p>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <p>Accredited by</p>
                  </div>
                  <div className="col-md-6">
                    <p>Endorsed by</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
