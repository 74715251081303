import React from "react";
import Header from "../components/header";
import { useNavigate } from "react-router-dom";

function LearnNumber() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };
  return (
    <div className="landing-wrapper">
      <div className="landing-container">
        <Header />
        <div className="side-info">
          <button className="back-button" onClick={handleBack}>
            <img src="/Images/back-button.svg" alt="back" /> Back
          </button>
        </div>
        <div className="game-wrapper">
          <div className="iframe-container">
            <iframe
              title="Game"
              src="https://mandaean-learn-number.netlify.app/"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnNumber;
