// App.js
import React from "react";
import { useForm } from 'react-hook-form'
import { Form, Button } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginSchema } from "../utils/validationSchema";
import axios from "axios";
import { serverPath } from "../config/keys";
import { toast } from "react-toastify";

function LogIn({setShowLogin,setIsAuthenticate}) {
    const {
        handleSubmit,
        register,
        formState: { errors },
      } = useForm({ resolver: yupResolver(LoginSchema) })
    const registerUser = async (values) => {
        try {
          const response = await axios.post(`${serverPath}/auth/login`, values)
          const { status,data:{message,token} } = response
          if (status === 200) {
            localStorage.setItem("token",token)
            setShowLogin(false)
            setIsAuthenticate(true)
            toast.success(message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            })
          }
        } catch (error) {
          if (error?.response?.data) {
            toast.error(error?.response?.data?.message || "Internal server error!", {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            })
          }
        }
      }
  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        <div class="login-cover">
          <img src="/Images/cat.png" className="cat-img" alt="" />
        </div>
        <div className="login-form">
            <div className="login-popup-content">
              <h2>Sign In</h2>

              <Form onSubmit={handleSubmit(registerUser)}>
                <Form.Group className="full-w">
                  <Form.Control
                    className={`${errors?.email ? `error-border` : ``}`}
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    {...register('email')}
                  />
                  {errors?.email ? (
                    <span className="error-msg">{errors.email.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group className="full-w">
                  <div className="pass-show">
                    <Form.Control
                      className={`${errors?.password ? `error-border` : ``}`}
                      type={/* showPassword ? */ 'text' /* : 'password' */}
                      placeholder="Password"
                      name="password"
                      {...register('password')}
                    />
                  </div>

                  {errors?.password ? (
                    <span className="error-msg">{errors.password.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <div className="full-w">
                  <Button className="auth-btn" type="submit">Log In</Button>
                </div>
              </Form>
            </div>
        </div>
        </div>
    </div>
  );
}

export default LogIn;
