import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Register from "../Auth/register";
import LogIn from "../Auth/login";
import { Link } from "react-router-dom";

const Header = () => {
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const [isAuthenticate, setIsAuthenticate] = useState(
    localStorage.getItem("token") ? true : false
  );
  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticate(false);
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsAuthenticate(true);
    }
  }, [localStorage.getItem("token")]);

  return (
    <div className="header">
      <Link to={"/"}>
        <div className="logo-container">
          <img src="/Images/logo.png" alt="Logo" className="logo" />
        </div>
      </Link>
      <div className="header-buttons">
        <Link to={"/leader-board"} className="leader-board-link">
          Leaderboard
        </Link>
        {isAuthenticate ? (
          <button className="login-button" onClick={() => handleLogout()}>
            Logout
          </button>
        ) : (
          <>
            <button
              className="login-button"
              onClick={() => setShowLogin(!showLogin)}
            >
              Login
            </button>
            <button
              className="register-button"
              onClick={() => setShowRegister(!showRegister)}
            >
              Register
            </button>
          </>
        )}
      </div>
      <Modal
        size="md"
        show={showRegister}
        onHide={() => setShowRegister(!showRegister)}
        centered
        className="auth-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Register setShowRegister={setShowRegister} />
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={showLogin}
        onHide={() => setShowLogin(!showLogin)}
        centered
        className="auth-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <LogIn setShowLogin={setShowLogin} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;
