import React from "react";

import Header from "../../components/header";
import "./leaderboard.css";

const LeaderBoardData = [
  {
    id: 1,
    rank: "1",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Harshit Dubey",
    score: "10000",
  },

  {
    id: 2,
    rank: "2",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Gyanendra ",
    score: "10000",
  },
  {
    id: 3,
    rank: "3",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Ritesh Singh",
    score: "10000",
  },
  {
    id: 4,
    rank: "4",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Shikhar Singh",
    score: "10000",
  },

  {
    id: 5,
    rank: "5",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Mohd Faizan Ansari",
    score: "10000",
  },

  {
    id: 6,
    rank: "6",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Shafeeq",
    score: "10000",
  },

  {
    id: 7,
    rank: "7",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Syed Taurat",
    score: "10000",
  },

  {
    id: 8,
    rank: "8",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Yasser Sheikh",
    score: "10000",
  },
  {
    id: 9,
    rank: "9",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Vijay Kumar",
    score: "10000",
  },
  {
    id: 10,
    rank: "10",
    img: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1726559252~exp=1726562852~hmac=77699980b392abc3d470c8f2453ac925afcbc7726dc09f191f5bf3e20341cf95&w=826",
    name: "Neetesh",
    score: "10000",
  },
];

const LeaderBoard = () => {
  const handleBack = () => {
    window.history.back();
  };
  return (
    <div className="landing-wrapper">
      <div className="landing-container">
        <Header />
        <div>
          <div className="side-info">
            <button className="back-button" onClick={handleBack}>
              <img src="/Images/back-button.svg" alt="back" /> Back
            </button>
            <h2 className="head-name">
              <span>Leader</span>board
            </h2>
          </div>

          <div className="sub-cat-wrapper leader-board-container">
            <div className="leader-board-wrapper">
              {LeaderBoardData.map((data) => (
                <div className="leader-card">
                  <div className="d-flex align-items-center gap-3">
                    <div className="leader-rank">{data?.rank}</div>
                    <div className="leader-img">
                      <img src={data.img} alt="img" />
                    </div>
                    <h3>{data?.name || "---"}</h3>
                  </div>

                  <div className="score-section">
                    <img src="/Images/trophy.png" alt="" />
                    <p>{data?.score || "---"}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
