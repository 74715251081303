import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="landing-wrapper page-not-found">
      <div className="page-not-found-content">
        <img src="/Images/404.jpg" alt="not-found" />
        <h2>Page Not Found</h2>
        <Link to="/">Go to Home</Link>
      </div>
    </div>
  );
}

export default NotFound;
